import React from "react";
import { Col, Row } from "antd";

class ContentManagement extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/office-calendar-640.webp"
              />
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/office-calendar-640.jpg"
              />
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/office-calendar-640.jpg"
                className="homepage-image"
                alt="office calendar & diary"
                title="office calendar & diary"
                height="100%"
                width="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Office &amp; Executive Management</h2>
            <p>
              Use DayViewer Pro or Team Rooms as an Office Diary or Executive
              Management system so everyone knows where they should be
              throughout the week.
            </p>
            <p>
              Utilising a Team Room allows the schedule to be shared, clearly
              and easily. And your members can view it anytime (and receive
              timely reminders).
            </p>
            <p>
              Make DayViewer your executive diary and your execs and management
              will not miss (or be surprised by) an appointment or important
              meeting!
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentManagement;
